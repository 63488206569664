// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-indoor-environment-tsx": () => import("./../../../src/pages/indoor-environment.tsx" /* webpackChunkName: "component---src-pages-indoor-environment-tsx" */),
  "component---src-pages-outdoor-environment-tsx": () => import("./../../../src/pages/outdoor-environment.tsx" /* webpackChunkName: "component---src-pages-outdoor-environment-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-templates-room-tsx": () => import("./../../../src/templates/room.tsx" /* webpackChunkName: "component---src-templates-room-tsx" */)
}

