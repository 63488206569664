/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/styles/global.css';
import * as React from 'react';
import { Helmet } from 'react-helmet';

function wrapRootElement({ element }) {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="p.typekit.net" />
        <link rel="preconnect" href="cdn.typekit.net" />
      </Helmet>
      {element}
    </>
  );
}

export { wrapRootElement };
